// Step 1: Import React
import * as React from 'react'
import Layout from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'
// Step 2: Define your component
const AboutPage = () => {
  return (
    <Layout pageTitle="About Me">
      <h2 className='text-3xl text-center font-bold p-12'>Sobre mi.</h2>
      <div className='m-8 lg:inline-block flex flex-col'>
        <div className='lg:float-left float-none lg:mr-5 lg:mb-5 mx-auto'>
          <StaticImage className="lg:rounded-2xl rounded-full w-72 h-72 shadow-lg" src='../images/VictorEscobar.jpg' alt='Foto de Victor Escobar' layout="fixed" width={250} height={250}/>
        </div>
        <p className='text-lg text-justify mt-5'>
          Hola, me presento.<br/><br/>
          Mi nombre es Victor Escobar, soy desarrollador de software. Desarrollo sistemas en C# .Net , he desarrollado sistemas de administración y gestión empresarial, aplicando buenas prácticas de programación, el principio SOLID, arquitectura de software y patrones de diseños.<br/><br/>
          Considero que tengo una buena base de conocimientos y experiencia que me gustaría transmitir, a través de este blog, para poder enseñar sobre buenas prácticas y desarrollos de sistemas.<br/><br/>
          Haré algunos proyectos para ir aplicando los conocimientos y poco a poco ir mejorando los proyectos hasta tener algo que solucione un problema en concreto.<br/><br/>
          A pesar de que tengo varios años estudiando programación, soy consiente que me falta mucho por aprender y que poco a poco iré llenando esos huecos y lo que aprenda lo compartiré en este blog.<br/><br/>
          Espero que este blog te sea de mucha ayuda.<br/><br/>
        </p>
      </div>
    </Layout>
  )
}
// Step 3: Export your component
export default AboutPage